export default {
  methods: {
    helloWorld() {
      this.$f7.dialog.alert('Hello World!');
    },

    updateUsername(e) {
      this.username = e.target.value;
      this.$update();
    },

    updatePassword(e) {
      this.password = e.target.value;
      this.$update();
    },

    alertLoginData() {
      this.$f7.dialog.alert('Username: ' + this.username + '<br>Password: ' + this.password, () => {
        this.$f7.loginScreen.close();
      });
    }

  },
  id: '9d8a411802',

  render() {
    return function (ctx_1, data_1, root) {
      function isArray(arr) {
        return Array.isArray(arr);
      }

      function isFunction(func) {
        return typeof func === 'function';
      }

      function c(val, ctx) {
        if (typeof val !== "undefined" && val !== null) {
          if (isFunction(val)) {
            return val.call(ctx);
          } else return val;
        } else return "";
      }

      root = root || ctx_1 || {};
      var r = '';
      r += '\n  <div id="app">\n\n    <!-- Left panel with cover effect-->\n   \n\n    <!-- Your main view, should have "view-main" class -->\n    <div class="view view-main view-init safe-areas" data-url="/"></div>\n\n\n  </div>\n';
      return r;
    }(this);
  },

  styleScoped: false
};